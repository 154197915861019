import * as React from 'react'
import { graphql } from "gatsby"
import Footer from '../gatsby-theme-blog/components/home-footer'
import Layout from 'gatsby-theme-blog/src/components/layout'

const NotFoundPage = ({ data, location }) => (
  <div>
    <Layout location={location} title={"Page Not Found... please go back 👀"}>
      <h1><span role="img" aria-label="screaming face emoji">😱</span> NOT FOUND <span role="img" aria-label="screaming face emoji">😱</span></h1>
      <p>Oh no... you just hit a page that doesn&#39;t exist...</p>
      <Footer socialLinks={data.site.siteMetadata.social} />
    </Layout>
  </div>
)

export default NotFoundPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        social {
            name
            url
        }
      }
    }
  }
`